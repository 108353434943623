import clone from "just-clone"

const initialState = {
	demoDetails: null,
	menu: {},
	menuOptions: [],
	sessionId: null,
	form: {},
	formFields: [],
	formEditState: {},
	errorMessage: "",
	successMessage: "",
	isLoading: false,
	isDemoLoading : false,
	videoSrc: null,
	recordedEvents: [],
	voiceovers: [],
	showPasswordModal: false,
	invalidPassword: false
}


export default function fetchReducer(state = initialState, action){
	switch (action.type) {
		case 'SET_DEMO_DETAILS':
			return { ...state, 
				showPasswordModal: false,
				demoDetails: action.payload.demo, 
				sessionId: action.payload.session_id, 
				menu: action.payload.demo.menu || {}, 
				menuOptions: action.payload.demo.menu && action.payload.demo.menu.menu_id && action.payload.demo.menu.options || [],
			    voiceovers: action.payload.demo.voiceovers,
			}
		case 'SET_FORM':
			return {...state, form: action.payload, formFields: action.payload.fields,
			formEditState: action.payload.edit_state && action.payload.edit_state || null}
		case 'SET_ERROR':
			return { ...state, errorMessage: action.payload}
		case 'HIDE_ERROR':
			return { ...state, errorMessage: action.payload}
		case 'SHOW_SUCCESS':
			return {...state, successMessage: action.payload}
		case 'HIDE_SUCCESS':
			return {...state, successMessage: action.payload}
		case 'ENABLE_LOADER':
			return {...state, isLoading: true}
		case 'DISABLE_LOADER':
			return {...state, isLoading: false}
		case 'ENABLE_DEMO_LOADER':
			return {...state, isDemoLoading: true}
		case 'DISABLE_DEMO_LOADER':
			return {...state, isDemoLoading: false}
		case 'ADD_RECORDED_STEPS':
			return {...state, recordedEvents: [...state.recordedEvents, ...action.payload]}
		case 'RESET_RECORDED_STEPS':
			return {...state, recordedEvents: []}
		case 'SET_VIDEO_SRC':
			return {...state, videoSrc: action.payload}
		case 'SHOW_PASSWORD_MODAL':
			return {...state, showPasswordModal: action.payload}
		default: return state
	}
}