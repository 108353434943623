import React from "react"
import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import ClayLoaderGif from "../static/clay_loader.gif"

const override = css`
  z-index: 99999;
  border-top-width: 5px;
  border-left-width: 5px;
  border-right-width: 5px;
  border-bottom-width: 5px;
`;

export function Loader({isLoading}) {

    return (
        <div style={{position:'fixed', top:'50%', left:'50%', transform: 'translate(-50%, -50%)', zIndex: 99999}}>
        <ClipLoader color="#EF6F53" loading={isLoading} css={override} size={50} />
        </div>
      )
}

export function ClayLoader({isLoading}) {

  return (
    <div style={{position: "fixed", top: "50%", left: "50%", zIndex: "99999", transform: "translate(-50%, -50%)"}}>
      {isLoading && <img  src={ClayLoaderGif} width={100} height={100} />}
    </div>
  )
}
