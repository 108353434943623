import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route, Redirect } from 'react-router-dom'
import {useParams} from "react-router-dom"
import "./App.scss"
import {Loader,ClayLoader} from "./components/loader"

const Demo = lazy(() => import(
  './components/demo').then(module => ({
  default: module.Demo
})));

function InvalidUrl() {
  return (
    <div className="App">
        <p>Invalid url</p>
      </div>
  )
}

function LoadingPage() {
  const {accountId, demoId} = useParams();
  if (["DepraccGQmzZCRXkU"].includes(accountId)) {
    return (
      <ClayLoader isLoading={true} />
    )
  } else {
    return (
      <Loader isLoading={true} />
    )
  }
}

function App() {
    return (
      <div className="App">
        <BrowserRouter>
          <div>
            <Suspense fallback={<LoadingPage />}>
              <Routes>
                <Route path='/:accountId/:demoId' exact element={<Demo />}></Route>
                <Route path='/:linkId' exact element={<Demo />}></Route>
                <Route path='/' exact element={<InvalidUrl />}></Route>
              </Routes>
            </Suspense>
          </div>
        </BrowserRouter>
      </div>
    )
  }

export default App;

